<template>
	<AppLayout>
		<ServiceInfo :id="id" />
	</AppLayout>
	<HomeBackup></HomeBackup>
</template>

<script setup>
import AppLayout from "@/components/AppLayout";
import HomeBackup from "../home/components/HomeBackup.vue";

import { useRoute } from "vue-router";
import {ref} from 'vue'
import ServiceInfo from './components/serviceInfo.vue'


const route=useRoute()

const id=ref(route.query.id)




</script>

<style>
</style>
