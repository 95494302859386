import request from "@/utils/request";

// 增值服务详情
export function addedDetail(id) {
  return request.get(`/goods/value/added/detail/${id}`);
}

//增值服务下单
export function addedAdd(data) {
  return request.post(`/order/value/added/add`,data);
}
